

<footer class=" shadow shadow-white-900 border-t border-1 border-[#121212] border-opacity-10 dark:bg-[#121212] text-[#121212] bg-white dark:-text[#B9BABF]">
  <div class="container p-2 sm:p-4 py-6 md:px-8 mx-auto flex items-center flex-col gap-8">
    <nav class="flex justify-between w-full flex-wrap gap-5">
      <ul class="list-none flex gap-5 flex-wrap w-full">
        <li>
          <a [href]="['https://xgame.live/about-us']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] font-bold text-[16px] ">{{'footerpage.about_us' | translate }}</a>
        </li>
        <li>
          <a [href]="['https://xgame.live/contact-us']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] font-bold text-[16px]">{{'footerpage.contact_us' | translate }}</a>
        </li>
        <li>
          <a [href]="['https://xgame.live/teams']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-gradient font-bold text-[16px]">{{'footerpage.teams' | translate }}</a>
        </li>
        <li class="md:mr-auto">
          <a [href]="['https://xgame.live/blogs']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] font-bold text-[16px]">{{'footerpage.blogs' | translate }}</a>
        </li>
        <li>
          <a [href]="['https://xgame.live/games']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] font-bold text-[16px] ">{{'footerpage.games' | translate }}</a>
        </li>
        <li>
          <a [href]="['https://xgame.live/nft/drops']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] font-bold text-[16px]">{{'footerpage.nft_drops' | translate }}</a>
        </li>
        <li>
          <a [href]="['https://xgame.live/nft/marketplace']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] font-bold text-[16px]">{{'footerpage.nft_marketplace' | translate }}</a>
        </li>
        <li>
          <a [href]="['https://xgame.live/tokens']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] font-bold text-[16px]">{{'footerpage.tokens' | translate }}</a>
        </li>
      </ul>
    </nav>
    <div class="flex justify-between w-full flex-wrap">
      <div class="flex-row">
        <!-- <img class="w-auto h-12" src="../../../../assets/logo/footer-logo.webp"> -->
        <img class="h-12 w-auto hidden dark:block" src="../../../../assets/logo/main-logo-white.webp" alt="">
        <img class="h-12 w-auto block dark:hidden" src="../../../../assets/logo/main-logo-black.webp" alt="">
        <ul class="my-8 space-y-3 text-sm font-medium dark:text-white">
          <li class="flex gap-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
              <path fill-rule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" />
            </svg>
            <span class="flex flex-wrap items-center"> Varšavská 715/36, Vinohrady, 120 00 Prague 2</span>
          </li>
          <li class="flex gap-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
            </svg>
            <span class="flex flex-wrap items-center">+420 772 438 450</span></li>
          <li>admin&#64;xgame.co</li>
        </ul>
      </div>
      <div class="flex flex-wrap items-center justify-center content-center flex-col">
        <span class="uppercase">{{'footerpage.stay_connected' | translate }}</span>
        <ul class="inline-flex flex-wrap justify-center pb-6 space-x-6">
          <li>
            <a href="https://www.facebook.com/xgameweb3gamingplatform" target="_blank" class="hover:text-[#08CBCB] flex flex-wrap">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-9 h-9" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/officialxgame_/" target="_blank" class="hover:text-[#08CBCB] flex flex-wrap">
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" class="w-9 h-9">
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/xgame-official/" target="_blank" class="hover:text-[#08CBCB] flex flex-wrap">
              <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-9 h-9" viewBox="0 0 24 24">
                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </li>
          <li>
            <!-- <a href="https://x.com/XGameOfficial_" target="_blank" class="hover:text-[#08CBCB] flex flex-wrap">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-9 h-9" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a> -->
            <a href="https://x.com/XGameOfficial_" target="_blank" class="hover:text-[#08CBCB] flex flex-wrap">
              <svg fill="currentColor" version="1.1" id="svg5" xmlns:svg="http://www.w3.org/2000/svg"
                   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1668.56 1221.19"
                   style="enable-background:new 0 0 1668.56 1221.19;" xml:space="preserve" stroke-width="2" class="w-9 h-9">
                <g id="layer1" transform="translate(52.390088,-25.058597)">
                  <path id="path1009" d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99 h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"/>
                </g>
              </svg>
            </a>

          </li>
          <li>
            <a href="https://discord.gg/kPtuGyN5BN" target="_blank" class="hover:text-[#08CBCB] flex flex-wrap">
              <svg fill="currentColor" viewBox="0 0 24 24" class="w-9 h-9">
                <path d="M20.317 4.3698a19.7913 19.7913 0 0 0-4.8851-1.5152.0741.0741 0 0 0-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 0 0-.0785-.037 19.7363 19.7363 0 0 0-4.8852 1.515.0699.0699 0 0 0-.0321.0277C.5334 9.0458-.319 13.5799.0993 18.0578a.0824.0824 0 0 0 .0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 0 0 .0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 0 0-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 0 1-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0737.0737 0 0 1 .0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 0 1 .0785.0095c.1202.0991.246.1981.3728.2924a.077.077 0 0 1-.0066.1276 12.2986 12.2986 0 0 1-1.873.8914.0766.0766 0 0 0-.0407.1066c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 0 0 .0842.0286c1.961-.6067 3.9495-1.5219 6.0022-3.0294a.077.077 0 0 0 .0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6605a.061.061 0 0 0-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@XGameOfficial_" target="_blank" class="hover:text-[#08CBCB] flex flex-wrap">
              <svg fill="currentColor" viewBox="0 0 24 24" class="w-9 h-9">
                <path d="M23.499,6.203c-0.273-1.033-1.083-1.843-2.116-2.116C19.765,3.671,12,3.671,12,3.671s-7.765,0-9.383,0.416 c-1.033,0.273-1.843,1.083-2.116,2.116C0.084,7.82,0.084,12,0.084,12s0,4.18,0.417,5.797c0.273,1.033,1.083,1.843,2.116,2.116 c1.618,0.417,9.383,0.417,9.383,0.417s7.765,0,9.383-0.417c1.033-0.273,1.843-1.083,2.116-2.116C23.916,16.18,23.916,12,23.916,12 S23.916,7.82,23.499,6.203z M9.797,15.547v-7.094L15.203,12L9.797,15.547z"/>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-wrap w-full justify-between gap-5">
      <nav class="flex flex-wrap gap-5">
        <ul class="list-none flex gap-5 flex-wrap">
          <li>
            <a [href]="['https://xgame.live/terms/terms-of-use']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] text-[14px] ">{{'footerpage.terms' | translate }}</a>
          </li>
          <li>
            <a [href]="['https://xgame.live/terms/privacy-policy']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] text-[14px] ">{{'footerpage.privacy_policy' | translate }}</a>
          </li>
          <li>
            <a [href]="['https://xgame.live/terms/copyright-notice']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] text-[14px] ">{{'footerpage.copyright_notice' | translate }}</a>
          </li>
          <li>
            <a [href]="['https://xgame.live/terms/cookie-policy']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] text-[14px] ">{{'footerpage.cookie_policy' | translate }}</a>
          </li>
          <li>
            <a [href]="['https://xgame.live/terms/legal-disclaimer']" routerLinkActive="!text-[#08CBCB]" class=" hover:text-[#08CBCB] text-[14px] ">{{'footerpage.legal_disclaimer' | translate }}</a>
          </li>
        </ul>
      </nav>
      <span class=" text-[14px]">{{ 'footerpage.copyright_2024' | translate }}</span>
    </div>
  </div>
</footer>







