<button type="button" data-dropdown-toggle="settings-dropdown-menu"
  class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm dark:text-white rounded-lg cursor-pointer">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>

</button>

  <div class="z-50 hidden my-4 text-base list-none text-[#121212] bg-white divide-y divide-gray-100 rounded-lg shadow dark:text-white dark:bg-[#202020] w-[250px] !-ml-[70px]" id="settings-dropdown-menu">
    <ul class="py-2 font-medium" role="none" id="accordion-nested-parent" data-accordion="collapse">
      <li>
        <a href="javascript:void(0)" class="block px-5 py-2 text-sm hover:bg-gray-100 dark:hover:bg-white/10 !text-[#121212]"
         role="menuitem" id="accordion-collapse-heading-network"  data-accordion-target="#accordion-collapse-network" aria-expanded="false" aria-controls="accordion-collapse-network">
          <div class="inline-flex items-center w-full">
            <span class="flex-1 flex space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 self-center">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
              </svg>


              <span class="text-[16px] self-center font-bold">{{'headerpage.network' | translate}}</span>
            </span>
            <span class="flex flex-wrap gap-2 capitalize">
              {{connected_network.name}}
              <svg data-accordion-icon class="w-2 h-2 rotate-180 shrink-0 self-center" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
              </svg>
            </span>
          </div>
        </a>
        <div id="accordion-collapse-network" class="hidden" aria-labelledby="accordion-collapse-heading-network">
          <div class="p-5 border border-gray-200 dark:border-gray-700 space-y-4">
            <div class="flex items-center justify-between" *ngFor="let network of networks">
              <div class="flex justify-between gap-4">
                <input id="default-radio-mainnet-mobile"
                (click)="selectedNetwork(network)"
                [checked]="connected_network.id === network.id"
                type="radio" value="" [name]="'default-radio'+network.id"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 self-center">
                <img class="h-auto w-[100px]" src="../../../../assets/logo/xode-logo-black-pink.webp">
              </div>
              <label for="default-radio-mainnet-mobile" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 self-center">
                {{network.name}}
              </label>
            </div>
          </div>
        </div>
      </li>

      <li>
        <a href="javascript:void(0)" class="block px-5 py-2 text-sm hover:bg-gray-100 dark:hover:bg-white/10 !text-[#121212]"
         role="menuitem" id="accordion-collapse-heading-2"  data-accordion-target="#accordion-collapse-language" aria-expanded="false" aria-controls="accordion-collapse-language">
          <div class="inline-flex items-center w-full">
            <span class="flex-1 flex space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 self-center">
                <path stroke-linecap="round" stroke-linejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
              </svg>


              <span class="text-[16px] self-center font-bold">{{'headerpage.language' | translate}}</span>
            </span>
            <span class="flex flex-wrap gap-2">
              <span [class]="'fi fi-'+(selectedLanguage.icon || 'us')+' w-5 h-5 me-1'"></span>

              <svg data-accordion-icon class="w-2 h-2 rotate-180 shrink-0 self-center" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
              </svg>
            </span>
          </div>
        </a>
        <div id="accordion-collapse-language" class="hidden" aria-labelledby="accordion-collapse-heading-2">
            <ul class="py-2 font-medium space-y-2" role="none">
              <li *ngFor="let language of languageList">
                <a href="javascript:void(0)"      [ngClass]="{'bg-gray-100': selectedLanguage.language_name === language.language_name}"
                class="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-white/10"
                (click)="changeLanguage(language)" role="menuitem">
                  <div class="inline-flex items-center">
                    <span [class]="'fi fi-'+language.icon+' w-6 h-6 me-1'"></span>
                    {{language.language_name}}
                  </div>
                </a>
              </li>
            </ul>
        </div>
      </li>
    </ul>
  </div>
