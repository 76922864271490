import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NavService } from '../services/layout/nav.service';
import { SharedModule } from '../shared/shared.module';
import { FullComponent } from './full.component';
import { FullRoutingModule } from './full-routing.module';
import { ConnectWalletComponent } from '../components/connect-wallet/connect-wallet.component';

@NgModule({
  declarations: [
    FullComponent,
    
  ],
  imports: [
    CommonModule,
    FullRoutingModule,
    SharedModule
  ],
  exports: [
  ],
  providers: [
    DecimalPipe,
    NavService
  ]
})
export class FullModule { }
