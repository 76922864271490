import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

// Smart Contract
export interface Contract {
  path?: string;
  title?: string;
  icon?: string;
  active?: boolean;
  disabled?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 767 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 767 ? false : true;

  // Full screen
  public fullScreen: boolean = false;


  constructor(private router: Router,private route: ActivatedRoute) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }
  MENUITEMS: Menu[] = [

    { path: "/home", title: "Games", icon: "about", type: "link" },
    // { path: "/blockscanner", title: "Block Scanner", icon: "blockscanner", type: "link" },
    // { path: "/smartcontract", title: "Smart Contract", icon: "tokens", type: "link" },
    {
      title: "NFT",
      icon: "user",
      type: "sub",
      active: false,
      children: [
    { path: "/blockscanner", title: "NFT Drops", icon: "blockscanner", type: "link" },
    { path: "/smartcontract", title: "NFT Marketplace", icon: "tokens", type: "link" },
      ],
    },
    { path: "/blogs", title: "Tokens", icon:"", type:"link" },
  ];

  LEGALMENUITEMS: Menu[] = [
    // { path: "", title: "Copyright Notice", icon: "home", type: "link" },
    { path: "/privacy-policy", title: "Privacy Policy", icon: "gem", type: "link" },
    // { path: "", title: "Terms of Use", icon: "gem", type: "link" },
    // { path: "", title: "Cookie Policy", icon: "gem", type: "link" },
    // { path: "", title: "Legal Disclaimer", icon: "gem", type: "link" },
  ];
  legal_items = new BehaviorSubject<Menu[]>(this.LEGALMENUITEMS);
  CONTRACTITEMS: Contract[] = [
    { path: "/smartcontract", title: "Create Smart Contract", icon: "plus-circle", active: false, disabled: false },
    { path: "/smartcontract/my-contract", title: "My Contracts", icon: "file-text", active: false, disabled: false },
    { path: "", title: "External Developers", icon: "settings", active: false, disabled: true },
    { path: "", title: "Buy Tokens", icon: "dollar-sign", active: false, disabled: true },

  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  contracts = new BehaviorSubject<Contract[]>(this.CONTRACTITEMS);

  private idSource = new BehaviorSubject<number | null>(null);
  currentId = this.idSource.asObservable();


  changeId(id: number | null) {
    this.idSource.next(id);
  }
}