

<header>
  <nav class="mx-auto flex items-center justify-between container p-2 sm:p-4 py-6 md:px-8">
    <div class="flex lg:flex-1">
      <a href="#" class="-m-1.5 p-1.5">
        <span class="sr-only">Your Company</span>
        <img class="h-8 w-auto hidden dark:block" src="../../../../assets/logo/main-logo-white.webp" alt="">
        <img class="h-8 w-auto block dark:hidden" src="../../../../assets/logo/main-logo-black.webp" alt="">
      </a>
    </div>
    <div class="hidden lg:flex lg:gap-x-2">
      <a
      [routerLink]="['/portfolio']" routerLinkActive="text-[#121212] hover:text-opacity-80 dark:text-[#08CBCB]  bg-black/10"
      class="flex gap-2 flex-wrap items-center text-[16px] font-bold leading-6 hover:text-[#121212] hover:text-opacity-80 dark:text-[#08CBCB]  hover:bg-black/10 p-[10px] rounded-xl">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
      </svg>

        <span>{{ 'headerpage.portfolio' | translate }}</span>
      </a>

      <a
      [routerLink]="['/transfer']" routerLinkActive="text-[#121212] hover:text-opacity-80 dark:text-[#08CBCB]  bg-black/10"
        class="flex gap-2 flex-wrap items-center text-[16px] font-bold leading-6 hover:text-[#121212] hover:text-opacity-80 dark:text-[#08CBCB]  p-[10px] hover:bg-black/10 rounded-xl">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
        </svg>

        {{ 'headerpage.transfer' | translate }}
      </a>
    </div>
    <div class="flex lg:flex-1 lg:justify-end">
      <button type="button" (click)="toggleDropdown()" class="lg:hidden inline-flex items-center justify-center rounded-md text-[#121212] text-opacity-100 dark:text-white "
      >
        <span class="sr-only">Open main menu</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>

      <app-language class="hidden lg:flex"></app-language>
      <!-- <app-connect-wallet></app-connect-wallet> -->
      <div class="relative hidden lg:flex" *ngIf="walletInfo!=null">
        <app-polkadot-identicon [address]="walletInfo.address" [width]="40" [height]="40"></app-polkadot-identicon>
        <div
        id="dropdownDefaultButtons" data-dropdown-toggle="dropdowns"
        class="absolute inset-0 bg-transparent cursor-pointer" >
        </div>
      </div>
      <button *ngIf="walletInfo==null" data-modal-target="defaultModal" data-modal-toggle="defaultModal"
      class="inline-flex items-center connect-wallet-button hover:text-black rounded-full border-0 py-2 px-6 text-base mt-4 md:mt-0 text-white lg:flex">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
            <path d="M2.273 5.625A4.483 4.483 0 0 1 5.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 3H5.25a3 3 0 0 0-2.977 2.625ZM2.273 8.625A4.483 4.483 0 0 1 5.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 6H5.25a3 3 0 0 0-2.977 2.625ZM5.25 9a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H15a.75.75 0 0 0-.75.75 2.25 2.25 0 0 1-4.5 0A.75.75 0 0 0 9 9H5.25Z" />
          </svg>
          <span class="text-sm font-semibold">{{ 'walletpage.connect_wallet' | translate }}</span>
      </button>
      <div id="dropdowns" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 w-[200px] !-ml-[30px]" *ngIf="walletInfo!=null">
        <div class="px-4 py-3">
            <span class="block text-sm dark:text-white-900 ">{{ 'walletpage.name' | translate }}: {{walletInfo.metaName}}</span>
            <span class="text-sm  dark:text-white-500 flex justify-between gap-1 w-full">
                <span>{{ 'walletpage.wallet' | translate }}:</span>
                <span class="flex justify-between" style="width: -webkit-fill-available;">
                    {{walletInfo.address_display}}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 self-center cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                    </svg>
                </span>


            </span>
            <span class="block text-sm  dark:text-white-500 truncate ">{{ 'walletpage.balance' | translate }}: {{balance}} XON</span>
        </div>
        <ul class="py-2" aria-labelledby="dropdownDefaultButtons">
            <li>
            <a href="https://dashboard.xgame.live/" class="px-4 py-2 text-sm dark:text-white-700 hover:bg-white/10 flex gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
                  </svg>
                <span>{{ 'walletpage.dashboard' | translate }}</span>
            </a>
            </li>
            <li>
            <a href="javascript:void(0)"
            id="successButton" data-modal-target="successModal" data-modal-toggle="successModal"
            (click)="disconnect()"
            class="px-4 py-2 text-sm dark:text-white-700 hover:bg-white/10 flex gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
            </svg>

                <span>{{ 'walletpage.disconnect' | translate }}</span>
            </a>
            </li>
        </ul>
      </div>
      <div id="defaultModal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <app-connect-wallet></app-connect-wallet>
      </div>
    </div>
  </nav>
  <div role="dialog" [ngClass]="{ 'hidden': !isExpanded }">
    <div class="lg:hidden fixed bg-white inset-y-0 right-0 z-10 w-full overflow-y-auto sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 p-2 sm:p-4 py-6 md:px-8">
      <div class="flex items-center justify-between">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Your Company</span>
          <img class="h-8 w-auto" src="../../../../assets/logo/main-logo-black.webp" alt="">
        </a>
        <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" (click)="toggleDropdown()">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

      </div>
      <div class="mt-6 flow-root mb-[50px]">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="space-y-2 py-6">
            <a routerLink="/portfolio" routerLinkActive="bg-black/10" class="-mx-2 flex gap-2 flex-wrap items-center rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:text-[#121212] hover:text-opacity-80 dark:text-[#08CBCB]  hover:bg-black/10 p-[10px]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
                </svg>
                <span>{{ 'headerpage.portfolio' | translate }}</span>
            </a>
            <a routerLink="/transfer" routerLinkActive="bg-black/10" class="-mx-2 flex gap-2 flex-wrap items-center rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:text-[#121212] hover:text-opacity-80 dark:text-[#08CBCB]  hover:bg-black/10 p-[10px]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                </svg>
                <span>{{ 'headerpage.transfer' | translate }}</span>
            </a>
          </div>
          <ul class="py-2 font-medium space-y-2" role="none" id="accordion-nested-parent-mobile" data-accordion="collapse">
            <li>
              <a href="javascript:void(0)" class="-mx-2 block px-3 py-2 text-sm hover:bg-black/10 dark:hover:bg-white/10 rounded-lg !text-[#121212]"
                role="menuitem" id="accordion-collapse-heading-network-mobile"  data-accordion-target="#accordion-collapse-network-mobile" aria-expanded="false" aria-controls="accordion-collapse-network-mobile">
                <div class="inline-flex items-center w-full">
                  <span class="flex-1 flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 self-center">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                    </svg>


                    <span class="text-[16px] self-center font-bold">{{'headerpage.network' | translate}}</span>
                  </span>
                  <span class="flex flex-wrap gap-2 text-[14px]">
                    {{connected_network.name}}
                    <svg data-accordion-icon class="w-2 h-2 rotate-180 shrink-0 self-center" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                    </svg>
                  </span>
                </div>
              </a>
              <div id="accordion-collapse-network-mobile" class="hidden" aria-labelledby="accordion-collapse-heading-network-mobile">
                <div class="py-5 px-3 space-y-4">
                  <div class="flex items-center justify-between" *ngFor="let network of networks">
                    <div class="flex justify-between gap-4">
                      <input id="default-radio-mainnet"
                      (click)="selectedNetwork(network)"
                      [checked]="connected_network.id === network.id"
                      type="radio" value="" name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 self-center">
                      <img class="h-auto w-[100px]" src="../../../../assets/logo/xode-logo-black-pink.webp">
                    </div>
                    <label
                      for="default-radio-mainnet"
                      class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 self-center">
                      {{network.name}}
                    </label>
                  </div>

                </div>
              </div>
            </li>

            <li>
              <a href="javascript:void(0)" class="-mx-2 block px-3 py-2 text-sm hover:bg-black/10 dark:hover:bg-white/10 rounded-lg !text-[#121212]"
                role="menuitem" id="accordion-collapse-heading-language-mobile"  data-accordion-target="#accordion-collapse-language-mobile" aria-expanded="false" aria-controls="accordion-collapse-language-mobile">
                <div class="inline-flex items-center w-full !text-[#121212]">
                  <span class="flex-1 flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 self-center">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                    </svg>


                    <span class="text-[16px] self-center font-bold">{{'headerpage.language' | translate}}</span>
                  </span>
                  <span class="flex flex-wrap gap-2">
                    <span [class]="'fi fi-'+(selectedLanguage.icon || 'us')+' w-5 h-5 me-1'"></span>

                    <svg data-accordion-icon class="w-2 h-2 rotate-180 shrink-0 self-center" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                    </svg>
                  </span>
                </div>
              </a>
              <div id="accordion-collapse-language-mobile" class="hidden" aria-labelledby="accordion-collapse-heading-language-mobile">
                  <ul class="py-2 font-medium space-y-2" role="none">
                    <li *ngFor="let language of languageList">
                      <a href="javascript:void(0)"      [ngClass]="{'bg-gray-100': selectedLanguage.language_name === language.language_name}"
                      class="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-white/10 rounded-lg"
                      (click)="changeLanguage(language)" role="menuitem">
                        <div class="inline-flex items-center">
                          <span [class]="'fi fi-'+language.icon+' w-6 h-6 me-1'"></span>
                          {{language.language_name}}
                        </div>
                      </a>
                    </li>
                  </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <hr class="opacity-60">
      <div class="flex items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-600 sticky b-0 bg-white gap-1 bottom-0" *ngIf="walletInfo==null">
          <button
           type="button"
           data-modal-target="defaultModal" data-modal-toggle="defaultModal"
           class="text-white w-full font-bold connect-wallet-button focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm px-5 py-2.5 text-center hover:text-[#121212]">
           {{ 'walletpage.connect_wallet' | translate }}
          </button>
      </div>
      <div class="mt-4 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 w-full" *ngIf="walletInfo!=null">
        <div class="grid grid-cols-1 px-14 py-3 text-center">
            <span class="block text-sm dark:text-white-900 ">{{walletInfo.metaName}}</span>
            <span class="block text-sm dark:text-white-500 justify-between gap-1 w-full">
                <span class="flex justify-center" style="width: -webkit-fill-available;">
                    {{walletInfo.address_display}}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 self-center cursor-pointer ml-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                    </svg>
                </span>
            </span>
        </div>
        <div class="text-center py-3">
            <span class="block text-base dark:text-white-500 truncate ">{{ 'walletpage.balance' | translate }}: {{ balance }} XON</span>
        </div>
        <ul class="flex justify-around py-2" aria-labelledby="dropdownDefaultButtons">
            <li>
                <a href="https://dashboard.xgame.live/" class="px-4 py-2 text-sm dark:text-white-700 hover:bg-white/10 flex gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
                    </svg>
                    <span>{{ 'walletpage.dashboard' | translate }}</span>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)"
                    id="successButton" data-modal-target="successModal" data-modal-toggle="successModal"
                    (click)="disconnect()"
                    class="px-4 py-2 text-sm dark:text-white-700 hover:bg-white/10 flex gap-2"
                >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
                </svg>
                <span>{{ 'walletpage.disconnect' | translate }}</span>
                </a>
            </li>
        </ul>
      </div>
    </div>
  </div>
</header>



