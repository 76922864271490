import { Component } from '@angular/core';
import { NavService } from '../../services/layout/nav.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  currentYear: number;
  id:any = 0;

  constructor(
    private navService: NavService,
  ) {
    this.currentYear = new Date().getFullYear();
  }
  ngOnInit(): void {
    this.navService.currentId.subscribe(id => {
      this.id = id;
    });
  }
}
