import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';
import { content } from "./shared/routes/routes";
import { PolkadotIdenticonComponent } from './shared/components/polkadot-identicon/polkadot-identicon.component';
import { AdminGuard } from './shared/guard/admin.guard';
import { full } from './shared/routes/full.routes';
import { FullComponent } from './shared/full/full.component';
import { WalletGuard } from './shared/guard/wallet.guard';

const routes: Routes = [
  { path: '', redirectTo: '/portfolio', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: 'polkadot-identicon/:wallet_address',
    component: PolkadotIdenticonComponent
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [WalletGuard],
    children: full
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
