import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit{
  constructor(){
    window.scrollTo(0, 0);
  }
  isDense = false;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header = document.getElementById('header');
    // if (window.scrollY > 50) {
    //   header?.classList.add('bg-[#121212]', 'shadow' ,'shadow-white-900','border-b','border-1' ,'border-gray-800');
    // } else {
    //   header?.classList.remove('bg-[#121212]', 'shadow' ,'shadow-white-900','border-b','border-1' ,'border-gray-800');
    // }
        if (window.scrollY > 50) {
      header?.classList.add('bg-white', 'shadow' ,'shadow-white-900','border-b','border-1' ,'dark:border-gray-800', 'border-[#121212]', 'border-opacity-10','border-b');
    } else {
      header?.classList.remove('bg-white', 'shadow' ,'shadow-white-900','border-b','border-1' ,'dark:border-gray-800', 'border-[#121212]', 'border-opacity-10','border-b');
    }
  }
  ngOnInit(): void {
  }
}
