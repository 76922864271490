import { Component } from '@angular/core';
import { CookiesService } from '../../services/cookies/cookies.service';
import { LanguagesService } from '../../services/languages/languages.service';
import { LanguageModel } from '../../model/language.model';
import { NetworkModel } from '../../model/network.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss'
})
export class LanguageComponent {

  constructor(
    private cookiesService: CookiesService,
    private languageService: LanguagesService
  ) {
    environment.network.forEach(networkGroup => {
      networkGroup.networks.forEach(network => {
        this.networks.push({
          id: network.id,
          name: network.name,
          wsProviderEndpoint: network.wsProviderEndpoint,
          domain: network.domain,
          net_name: network.net_name,
          is_default: network.is_default
        });
        if (network.is_default) {
          const selectedNetwork = this.cookiesService.getCookieArray('network');
          if (selectedNetwork == null) {
            this.cookiesService.setCookieArray('network', network)
            this.connected_network = network;
          };
          this.connected_network = selectedNetwork != null ? this.cookiesService.getCookieArray('network') : network;
        };
      });
    });
  }

  isDropdownVisible = false;
  public languageList: LanguageModel[] = [];
  public selectedLanguage: LanguageModel = new LanguageModel();

  networks: NetworkModel[] = [];
  connected_network: NetworkModel = new NetworkModel;

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  changeLanguage(language: any) {
    if(this.selectedLanguage.language_name!=language.language_name){
      this.cookiesService.setCookieArray('language', language);
      this.isDropdownVisible = false;
      window.location.reload();
    }
  }

  getLanguageList(): void {
    this.languageService.getLanguageJSON().subscribe(
      (data: LanguageModel[]) => {
        this.languageList = data;
        if(this.cookiesService.getCookieArray('language')==null){
          this.selectedLanguage = this.languageList[0]
          this.cookiesService.setCookieArray('language',this.languageList[0]);
        }else{
          // this.selectedLanguage = this.cookiesService.getCookieArray('language');
        }
      },
      (error) => {
        console.error('Error loading language JSON:', error);
      }
    );
  }

  selectedNetwork(network: any){
    if(network.net_name != this.connected_network.net_name){
      this.connected_network = network;
      window.location.href = network.domain;
    }
  }

  ngOnInit() {
    // this.get_langauge_list();
    this.languageService.languageList$.subscribe((data) => {
      this.languageList = data;
      if (data==null || data) {
        this.getLanguageList();
      } else {
        this.languageList = data;
        if(this.cookiesService.getCookieArray('language')==null){
          this.selectedLanguage = this.languageList[0]
        }else{
          this.selectedLanguage = this.cookiesService.getCookieArray('language');
        }
      }
    });
    if(this.cookiesService.getCookieArray('language')==null){
      this.selectedLanguage = this.languageList[0]
    }else{
      this.selectedLanguage = this.cookiesService.getCookieArray('language');
    }
  }
}
