import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Menu, NavService } from '../../services/layout/nav.service';
import { CookiesService } from '../../services/cookies/cookies.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { LanguageModel } from '../../model/language.model';
import { NetworkModel } from '../../model/network.model';
import { LanguagesService } from '../../services/languages/languages.service';
import Swal from 'sweetalert2'
import { PolkadotService } from '../../services/polkadot/polkadot.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  isMenuOpen: boolean = false;
  facebook: string = environment.FacebookURL;
  instagram: string = environment.InstagramURL;
  linkedin: string = environment.LinkedInURL;
  isDarkMode = false;
  isToggleWalletInfo: boolean = true;
  balance: string = '0';
  walletInfo: any = "";

  public menuItems: Menu[] = [];
  public open: boolean = false;
  public openConnectWallet: boolean = false;
  public id: any = 0;
  iframeSrc: string = "";
  private subscription: Subscription | undefined;
  constructor(
    private navService: NavService,
    private cookiesService: CookiesService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private languageService: LanguagesService,
    private polkadotService: PolkadotService,
  ){
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    const cookiesLang = this.cookiesService.getCookieArray('language') ? this.cookiesService.getCookieArray('language').code : 'en';
    const lang = cookiesLang == '' || cookiesLang == undefined ? browserLang : cookiesLang;
    translate.use(cookiesLang);
    this.navService.items.subscribe(menuItems => {
      this.menuItems = menuItems;
    });

    environment.network.forEach(networkGroup => {
      networkGroup.networks.forEach(network => {
        this.networks.push({
          id: network.id,
          name: network.name,
          wsProviderEndpoint: network.wsProviderEndpoint,
          domain: network.domain,
          net_name: network.net_name,
          is_default: network.is_default
        })
        if (network.is_default) {
          const selectedNetwork = this.cookiesService.getCookieArray('network');
          if (selectedNetwork == null) {
            this.cookiesService.setCookieArray('network', network)
            this.connected_network = network;
          };
          this.connected_network = selectedNetwork != null ? this.cookiesService.getCookieArray('network') : network;
        };
      });
    });
    this.navService.currentId.subscribe(id => {
      this.id = id;
    });

    if(this.cookiesService.getCookieArray('wallet-info')!=null){
      this.iframeSrc = location.origin + "/polkadot-identicon/"+this.cookiesService.getCookieArray('wallet-info').address+"?size=60";
    }

    this.walletInfo = this.cookiesService.getCookieArray('wallet-info');
    if (this.walletInfo) this.polkadotService.subscribeToBalance(this.walletInfo.address);
    this.polkadotService.getBalance().subscribe(balance => {
      this.balance = balance;
    });

    this.languageService.languageList$.subscribe((data) => {
      this.languageList = data;
      if (data==null || data) {
        this.getLanguageList();
      } else {
        this.languageList = data;
      }
    });
  }
  isDropdownVisible = false;
  public languageList: LanguageModel[] = [];
  public selectedLanguage: LanguageModel = new LanguageModel();

  networks: NetworkModel[] = [];
  connected_network: NetworkModel = new NetworkModel;

  changeLanguage(language: any) {
    if(this.selectedLanguage.language_name!=language.language_name){
      this.cookiesService.setCookieArray('language', language);
      this.isDropdownVisible = false;
      window.location.reload();
    }
  }

  getLanguageList(): void {
    this.languageService.getLanguageJSON().subscribe(
      (data: LanguageModel[]) => {
        this.languageList = data;
        if(this.cookiesService.getCookieArray('language')==null){
          this.selectedLanguage = this.languageList[0]
          this.cookiesService.setCookieArray('language',this.languageList[0]);
        }else{
          // this.selectedLanguage = this.cookiesService.getCookieArray('language');
        }

      },
      (error) => {
        console.error('Error loading language JSON:', error);
      }
    );
  }

  selectedNetwork(network: any){
    if(network.net_name != this.connected_network.net_name){
      this.connected_network = network;
      window.location.href = network.domain;
    }
  }

  // Function to toggle the mobile menu
  closeMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  isCollapsed: boolean = true;

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  isExpanded = false;

  toggleDropdown() {
    this.isExpanded = !this.isExpanded;
  }

  toggleWalletInfo() {
    if(this.walletInfo!=null){
      this.isToggleWalletInfo = !this.isToggleWalletInfo;
    }
  }

  pageSweetAlert(success: boolean, swalTitle: string, swalText: string) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        didClose: () => {
            window.location.reload();
        },
    })
    Swal.fire({
        icon: success ? 'success' : 'error',
        title: swalTitle,
        text: swalText
    }).then((result)=>{
      if(result.isConfirmed){
        location.reload();
      }
    })
  }

  disconnect(){
    this.walletInfo = null;
    this.cookiesService.deleteAllCookie();
    this.pageSweetAlert(true, 'Disconnected', 'Your wallet was disconnected successfully.')
  }

  toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
    const htmlElement = document.documentElement;
    // if(localStorage.getItem('color-theme') == "dark"){
    //   localStorage.setItem('color-theme','light');
    //   htmlElement.classList.remove('dark');
    // }else{
    //   htmlElement.classList.add('dark');
    //   localStorage.setItem('color-theme','dark');
    // }
  }

}
