import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'portfolio',
    loadChildren: () =>
      import('../../module/portfolio/portfolio.module').then(
        (m) => m.PortfolioModule
      ),
  },
  {
    path: 'transfer',
    loadChildren: () =>
        import('../../module/transfer/transfer.module').then(
            (m) => m.TransferModule
        ),
    },
];
