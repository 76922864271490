import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
;

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ConnectWalletComponent } from '../components/connect-wallet/connect-wallet.component';
import { NavService } from '../services/layout/nav.service';
import { ConnectWalletMobileComponent } from '../components/connect-wallet-mobile/connect-wallet-mobile.component';
import { LanguageComponent } from '../components/language/language.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    // ConnectWalletComponent,
    // ConnectWalletMobileComponent,
    LanguageComponent,
    
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    SharedModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    DecimalPipe,
    NavService
  ]
})
export class LayoutModule { }
